<template>
  <Component :is="getFooterByType" :items="footerContent || []" />
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, inject } from 'vue';
import type { FooterValue, Footers, WebSettings, FooterVariant } from '~/types';
import { useI18n } from '#imports';

const webSettings = inject('settings') as {
  footer: Footers['items'],
  settings: WebSettings,
};

const { locale } = useI18n();
const footerContent = computed<FooterValue | null>(() =>
  webSettings.footer.find(item => (item.locale === locale.value))?.content?.[0].value || null
);

const getFooterByType = computed(() => {
  const style: FooterVariant = footerContent.value?.style || 'type1';
  const mapping: Record<FooterVariant, any> = {
    type1: defineAsyncComponent(() => import('./../footer/BaseFooter01.server.vue')),
    type2: defineAsyncComponent(() => import('./../footer/BaseFooter02.server.vue')),
  };

  return mapping[style];
});
</script>
